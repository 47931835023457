export const onRouteUpdate = () => {
    startup()

    document.querySelector('meta[name="generator"]') &&
        document.querySelector('meta[name="generator"]').remove()

    // Temporary fix for MEGAMENU logo size error START
    if ( document.querySelector('.elementskit-nav-logo img') ) {
        const navLogoImg = document.querySelector('.elementskit-nav-logo img')

        navLogoImg.setAttribute('height', 50)
        navLogoImg.setAttribute('width', 109)
    }
    // Temporary fix for MEGAMENU logo size error END
    
}

export const onInitialClientRender = () => {
    const dataLayer = window.dataLayer || []
    dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
}

// Startup checks and functions
const startup = () => {
    const tabsCheck = document.querySelectorAll('.elementor-tabs').length
    const videoCheck = document
        .querySelectorAll('[data-widget_type="video.default"]').length
    const videoCheckLegacy = document.querySelectorAll('.youtube').length
    const formIframes = document.querySelectorAll(
        'iframe[src*="info.grm"]:not(.grm-pardot-form)'
    )
    const stickyCheck = document.querySelectorAll('[data-settings*="sticky"]')
        .length
    const elementorTocCheck = document
        .querySelectorAll('.elementor-widget-table-of-contents').length
    const careersCheck = document
        .querySelectorAll('.career-tabs').length

    const mailroomCheck = document.querySelectorAll('svg [id^="item-"]').length
    const swiperCheck = document.querySelectorAll('.elementor-slides-wrapper')
        .length
    const searchPageCheck = document
        .querySelectorAll('[data-elementor-id="23924"]').length
    const searchButtonCheck = document.querySelectorAll('.grm-search svg')
        .length
    const hamburgerCheck = document
        .querySelectorAll('.elementskit-menu-hamburger').length
    const langSwitcherCheck = document
        .querySelectorAll('nav.cpel-switcher__nav').length
    const tabListCheck = document.querySelectorAll('.e-n-tabs').length
    const siteLangSelectCheck = 
        document.querySelectorAll('.cpel-switcher__nav').length
    const videoEmbedImgOverlayCheck = document
        .querySelectorAll('.elementor-custom-embed-image-overlay').length
    const backgroundImagesCheck = document.querySelectorAll('[data-bg]').length

    const megaMenuCheck = document
        .querySelectorAll('.elementskit-megamenu-panel').length
    const elementorMegaMenuCheck = document
        .querySelectorAll('nav.e-n-menu').length
    

    if ( backgroundImagesCheck > 0 ) setBackgroundImages()
    if ( elementorMegaMenuCheck > 0 ) elementorMegaMenuInit()
    if ( megaMenuCheck > 0 ) megaMenuFix()

    if ( tabsCheck > 0) {
        initTabs()
        tabsOnClick()
    }

    if ( swiperCheck > 0) {
        addAsyncScriptBody("https://unpkg.com/swiper/swiper-bundle.min.js")
        addAsyncScriptBody("/js/swiper-init.js")

        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.5/swiper-bundle.min.css';
        document.body.appendChild(link);
    }

    if ( formIframes.length > 0) formIframeSize()
    if ( videoCheck > 0) videoEmbedInit()
    if ( videoCheckLegacy > 0) videoEmbedLegacyInit()
    if ( elementorTocCheck > 0) {
        elementorTocRenderList(elementorTocCheck)
        document.onscroll = () => {
            if (stickyCheck > 0)
                elementorStickyFx(stickyCheck)
        }
    }
    if (careersCheck > 0) careersTabs()

    renderAnimations()
    smoothScrollTo()
    webFlipEvent()

    lpBannerFix()

    if (mailroomCheck > 0) mailroomHighlight()
    if (searchPageCheck > 0) loadSiteSearch()

    if (searchButtonCheck > 0)
        document.querySelector('.grm-search svg')
            .addEventListener('click', loadSiteSearch)

    if (hamburgerCheck > 0) hamburgerChange()
    
    if (tabListCheck > 0) elementorTabs.init()
    
    if (siteLangSelectCheck > 0) setTimeout(siteLangSelect, 100)
    if (langSwitcherCheck > 0)  setTimeout(langSwitcherChange, 200)

    if (videoEmbedImgOverlayCheck > 0) videoEmbedImgOverlay()

    if (document.getElementById('top-image'))
        svgParralax.init()
}

const setBackgroundImages = () => {
    const bgImages = document.querySelectorAll('[data-bg]')

    bgImages.forEach((bgImage) => {
        bgImage.style.backgroundImage = 
            'url('+ bgImage.getAttribute('data-bg') +')'
    })
}

const addAsyncScriptBody = (src) => {
    const scriptTag = document.createElement("script")
    scriptTag.src = src
    scriptTag.async = true
    document.body.appendChild(scriptTag)
}

const siteLangSelect = () => {
    const lang = document.querySelector('html').getAttribute('lang')
    const selectedContainer = document.querySelector('.cpel-switcher__toggle')
    const listItems = document.querySelectorAll('.cpel-switcher__list li')
    const switcherLinks = document.querySelectorAll('.cpel-switcher__nav a')
    
    let altUris = document.querySelectorAll('link[rel="alternate"]')
    let active = document.querySelector('a[data-lang="'+ lang +'"]')
    let defaultLang = document.querySelector('.cpel-switcher__toggle a')
    let icon = defaultLang.querySelector('i')

    switcherLinks.forEach(link => {
        let langCode = link.getAttribute('data-lang').toLowerCase()

        altUris.forEach((uri) => {
            if ( uri.getAttribute('hreflang')
                .match(/[a-z]{2}-[A-Z]{2}/) ) {

                let uriHref = ( uri.getAttribute('href') ) ?
                    uri.getAttribute('href')
                    .replace(/^(https|http)?:\/\/(.*?)\//, '/') : '/'
                let uriLang = uri.getAttribute('hreflang').toLowerCase()
    
                if ( uriLang === langCode ) 
                    link.setAttribute('href', uriHref)

            }
        })
    })
    
    if ( !active ) return

    active.appendChild(icon)
    selectedContainer.prepend(active)

    listItems.forEach(item => {
        if ( item.childNodes.length === 0 ) {
            item.append(defaultLang)
        }
    })

    if ( process.env.GATSBY_ENV === 'PROD' ) {
        document.querySelector('a[data-lang="pt-BR"]') &&
            document.querySelector('a[data-lang="pt-BR"]').closest('li').remove()
    }
}

const videoEmbedImgOverlay = () => {
    const videoEmbedImgOverlayElements = document
        .querySelectorAll('.elementor-custom-embed-image-overlay')

    videoEmbedImgOverlayElements.forEach((overlay) => {
        overlay.style.backgroundColor = '#fff'
        let video = overlay.closest('.elementor-wrapper')
                .querySelector('iframe')
        let videoSettings = JSON.parse(overlay.closest('.elementor-widget-video')
            .getAttribute('data-settings'))
        let srcAttributes = '?autoplay=1&enablejsapi=1'

        if ( videoSettings.video_type === 'youtube' )
            video['data-src'] = videoSettings.youtube_url
                .replace('watch?v=', 'embed/')

        if ( videoSettings.video_type === 'vimeo' )
            video['data-src'] = videoSettings.vimeo_url

        if ( videoSettings.image_overlay )
            delete videoSettings.image_overlay

        overlay.closest('.elementor-widget-video')
            .setAttribute('data-settings', JSON.stringify(videoSettings))
        
        videoSettings.controls === 'yes' ? 
            srcAttributes += '&controls=1' : srcAttributes += '&controls=0'
        videoSettings.mute === 'yes' ?
            srcAttributes += '&mute=1' : srcAttributes += '&mute=0'
        videoSettings.loop === 'yes' ?
            srcAttributes += '&loop=1' : srcAttributes += '&loop=0'
        videoSettings.modestbranding === 'yes' ?
            srcAttributes += '&modestbranding=1' : srcAttributes += '&modestbranding=0'

        overlay.addEventListener('click', () => {
            overlay.remove()
            video.src += video['data-src'] +'/'+ srcAttributes

        })
    })
}

const elementorTabs = {
    init() {
        const tabLists = document.querySelectorAll('.e-n-tabs')

        tabLists.forEach((tabList) => {
            this.prep(tabList)
        })
    },
    prep(tabList) {
        const tabLinks  = 
            tabList.querySelectorAll('.e-n-tabs .e-n-tab-title')

        tabList.classList.add('e-activated')
    
        tabLinks.forEach((tabLink, i) => {
            let ref = tabLink.id

            tabLink.addEventListener('click', () => {
                let targetTab = 
                    tabList.querySelector('[aria-labelledby="'+ 
                    ref +'"] ')
                let activeTabs = tabList.querySelectorAll('.e-active')

                console.log(targetTab)

                activeTabs.forEach(activeTab => {
                    activeTab.classList.remove('e-active')    

                })

                if ( !targetTab ) return
                
                targetTab.classList.add('e-active')
                tabLink.classList.add('e-active')
            })
        })
    },
}

const hamburgerChange = () => {
    let hamburger = document.querySelector('.elementskit-menu-hamburger')
    let overlay = document.querySelector('.elementskit-menu-overlay')
    let closeMenu = document.querySelector('.elementskit-menu-close')
    let megaMenu = document.querySelector('.elementskit-menu-container')
    let innerToggles = document
        .querySelectorAll('.elementskit-navbar-nav > li > '+
            '.ekit-menu-nav-link i')

    const toggleMenuClose = (e) => {
        e.preventDefault()

        overlay.classList.remove('active')
        megaMenu.classList.remove('active')
        document.querySelector('body').style.overflow = ''
    }

    document.querySelector('body').style.overflow = ''
    
    hamburger.addEventListener('click', (e) => {
        

        e.preventDefault()

        overlay.classList.add('active')
        megaMenu.classList.add('active')
        document.querySelector('body').style.overflow = 'hidden'
    })

    closeMenu.addEventListener('click', toggleMenuClose)
    overlay.addEventListener('click', toggleMenuClose)

    innerToggles.forEach(toggle => {
        toggle.addEventListener('click', (e) => {
            e.preventDefault()

            let activeMenu = document.querySelector('.elementskit-dropdown-open')
            
            activeMenu &&
                activeMenu.classList.remove('elementskit-dropdown-open')

            toggle.closest('li').querySelector('.elementskit-megamenu-panel')
                .classList.toggle('elementskit-dropdown-open')

        })
    })
}

const langSwitcherChange = () => {
    const langToggle = {
        base: document.querySelector('.cpel-switcher__toggle'),
        link: document.querySelector('.cpel-switcher__toggle > '+
            'a:not([lang="en-US"])'),
        tFn(e) {
            e.preventDefault()
            this.classList.toggle('cpel-switcher__toggle--on')
        }
    }

    langToggle.base.addEventListener('click', langToggle.tFn)
    langToggle.link && 
        langToggle.link.addEventListener('click', langToggle.tFn)
}

const loadSiteSearch = () => {
    const scriptTag = document.createElement("script")
    scriptTag.src = "https://js.sitesearch360.com/plugin/bundle/3248.js"
    scriptTag.async = true
    document.body.appendChild(scriptTag)
}

const renderAnimations = () => {
    const itemParentSettings = document
        .querySelectorAll('.elementor-widget-image[data-settings]')

    itemParentSettings.forEach((item) => {
        let settings = JSON.parse(item.getAttribute('data-settings'))

        let transStepX = ( settings.motion_fx_translateX_speed ) ? 
            settings.motion_fx_translateX_speed.size * -1 : 0
        let transStepY = ( settings.motion_fx_translateY_speed ) ?
            settings.motion_fx_translateY_speed.size * -1 : 0
        let transStepR = (settings.motion_fx_rotateZ_speed) ?
            settings.motion_fx_rotateZ_speed.size : 0

        if ( settings.motion_fx_translateX_direction &&
            settings.motion_fx_translateX_direction === 'negative' ) 
                transStepX = transStepX * -1

        if ( settings.motion_fx_translateY_direction &&
            settings.motion_fx_translateY_direction === 'negative' )
                transStepY = transStepY * -1

        if ( settings.motion_fx_translateX_direction &&
            settings.motion_fx_translateX_direction === 'negative' )
                transStepR = transStepR * -1

        if ( transStepX || transStepY || transStepR ) 
            item.classList.add('parallax-item')
        if ( transStepX ) item.setAttribute('data-trans-x', transStepX)
        if ( transStepY ) item.setAttribute('data-trans-y', transStepY)
        if ( transStepR ) item.setAttribute('data-trans-r', transStepR)
    
        if ( settings._animation ) {
            item.classList.add(settings._animation)
            item.setAttribute('data-anim-delay', settings._animation_delay)
        }

        item.classList.add('elementor-animation')

    })

    window.onscroll = () => {
        const items = document.querySelectorAll('.elementor-animation')
        let windowScroll = window.scrollY / 10

        items.forEach((item) => {
            let itemBottomOffset = item.getBoundingClientRect().top +
                item.getBoundingClientRect().height +
                document.documentElement.scrollTop
            

            if ( itemBottomOffset > window.scrollY ) {
                if ( item.classList.contains('parallax-item') ) {
                    let transX = 
                        windowScroll * item.getAttribute('data-trans-x')
                    let transY = 
                        windowScroll * item.getAttribute('data-trans-y')
                    let transR = 
                        windowScroll * item.getAttribute('data-trans-r')
            
                    item.style.transform =
                        'translate(' + transX + 'px,' + transY + 'px) '+
                        'rotate('+ transR +'deg)'
                }
            }

            if ( itemBottomOffset < window.scrollY + window.innerHeight ) {
                if ( !item.getAttribute('data-anim-played') ) {
                    item.setAttribute('data-anim-played', 1)
                    
                    if ( item.classList.contains('slideInLeft') ) {
                        item.style.transform = 'translateX(-100%)' 
                    }   
                    
                    if ( item.classList.contains('zoomIn') ) {
                        item.style.transform = 'scale(0)'
                    }
                    
                    if (item.classList.contains('fadeIn')) {
                        item.style.opacity = '0'
                    }
                    
                    if ( item.getAttribute('data-anim-delay') ) {
                        let msTimeout = 
                            parseInt(item.getAttribute('data-anim-delay'))
    
                        setTimeout(() => {
                            item.style = ''
                            item.style.visibility = 'visible'
                        }, msTimeout)
                    }
                }
            }
        })
    }
}

const megaMenuFix = () => {
    const globHeader = document
        .getElementById('global-header')
    const megaMenus = document
        .querySelectorAll('.elementskit-megamenu-panel')
    const megaMenuRoot = document.querySelector('.elementor-widget-ekit-nav-menu')

    if ( megaMenuRoot ) {
        document.querySelector('.elementor-widget-ekit-nav-menu').
        style.pointerEvents = 'none'

        setTimeout(() => {
            document.querySelector('.elementor-widget-ekit-nav-menu').
                style.pointerEvents = ''
        }
        , 1000)
    }
    

    const fixHeader = () => {
        globHeader.querySelector('ul').style.pointerEvents = ''
        globHeader.classList.add('fixed')
        
        megaMenus.forEach((megaMenu) => {
            let offsetLeft = 
                parseInt(
                    document.querySelector('.ekit-nav-dropdown-hover')
                        .getBoundingClientRect().left
                )
            
            if ( offsetLeft > 50 )
                megaMenu.style.left = '-'+ offsetLeft + 'px'
        })
    }

    if ( globHeader && !globHeader.classList.contains('fixed')) {

        fixHeader()

        globHeader.addEventListener('mouseenter', fixHeader)
    }
}

const elementorMegaMenuInit = () => {
    const contentNodes = document.querySelectorAll('.e-n-menu-content > div') 
    const langSwitcherList = document.querySelector('.cpel-switcher__nav > ul')
    const hamburger = document.querySelector('button.e-n-menu-toggle')
    const menuWrapper = document.querySelector('.e-n-menu-wrapper')

    if ( langSwitcherList ) langSwitcherList.style.pointerEvents = 'all'

    if ( hamburger) {
        hamburger.addEventListener('click', (e) => {
            e.preventDefault()

            let state = hamburger.getAttribute('aria-expanded')

            if ( contentNodes.length > 0 && window.innerWidth < 1024 ) 
                contentNodes.forEach((contentNode) => {
                    let contentId = contentNode.id.split('-').pop()
                    let titleItem = document.getElementById('e-n-menu-title-'+ contentId)
                    
                    contentNode.style.display = 'none'

                    if ( titleItem.nextSibling )
                        titleItem.parentNode.insertBefore(contentNode, titleItem.nextSibling)

                    titleItem.addEventListener('click', () => {
                        let activeContent = document.querySelector('.e-active')

                        if ( activeContent ) {
                            activeContent.style.display = 'none'
                            activeContent.classList.remove('e-active')
                        }

                        contentNode.classList.add('e-active')
                        contentNode.style.display = 'block'
                    })
                })

            if ( state === 'false' ) {
                hamburger.setAttribute('aria-expanded', 'true')

                document.querySelector('body').style.overflow = 'hidden'

                menuWrapper.style.display = 'block'
                menuWrapper.style.position = 'fixed'
                menuWrapper.style.left = 0
                menuWrapper.style.top = '105px'
                menuWrapper.style.background = '#fff'
                menuWrapper.style.width = '100vw'
                menuWrapper.style.height = 'calc(100vh - 105px)'
                menuWrapper.style.overflowY = 'scroll'
                menuWrapper.style.paddingBottom = '13rem'

            } else {
                document.querySelector('body').style.overflow = ''

                hamburger.setAttribute('aria-expanded', 'false')
                menuWrapper.removeAttribute('style')

            }

        })
    }

    if ( contentNodes.length > 0 && window.innerWidth > 1024 ) 
        contentNodes.forEach((contentNode) => {
            let contentId = contentNode.id.split('-').pop()
            let titleItem = document.getElementById('e-n-menu-title-'+ contentId)
            let leaveAction = () => {
                contentNode.classList.remove('e-active')
                contentNode.removeAttribute('style')
                document.querySelector('body').style.overflow = ''
            }

            titleItem.addEventListener('mouseenter', () => {
                let activeContent = document.querySelector('.e-n-menu-content .e-active')

                if ( activeContent ) {
                    activeContent.classList.remove('e-active')
                    contentNode.removeAttribute('style')
                }

                

                contentNode.classList.add('e-active')
                contentNode.style.position = 'absolute'
                contentNode.style.left = '0'
                contentNode.style.width = '100vw'
                contentNode.style.maxWidth = 'initial'

                let offsetLeft = contentNode.getBoundingClientRect().left

                contentNode.style.left = '-'+ offsetLeft + 'px'
            })

            titleItem.addEventListener('mouseleave', () => {
                setTimeout(() => {
                    if ( contentNode.classList.contains('e-active-content') ) return

                    leaveAction()
                }, 100)
            })

            contentNode.addEventListener('mouseenter', () => {
                contentNode.classList.add('e-active-content')
                // document.querySelector('body').style.overflow = 'hidden'
            })

            contentNode.addEventListener('mouseleave', () => {
                contentNode.classList.remove('e-active-content')
                leaveAction()
            })
        })
}


const lpBannerFix = () => {
    let demoButton = document.querySelector('.demo-btn')

    if ( demoButton ) {
        let buttonText = demoButton.querySelector('span').textContent 
    
        if ( buttonText === '' ) demoButton.remove()
    }
}


const mailroomHighlight = () => {
    const items = document.querySelectorAll('[id^="item-"]')

    items.forEach((item) => {
        let target = '.'+ item.id
        let targetElement = document.querySelector(target)
        
        if ( targetElement ) {
            let targetClasses = document.querySelector(target).classList

            item.addEventListener('mouseenter', () => {
                targetClasses.add('dm-hover')
            })

            item.addEventListener('mouseleave', () => {
                targetClasses.remove('dm-hover')
            })
        }
    })
}

const careersTabs = () => {
    const tabs = document
        .querySelectorAll('.tabs article')
    const tabContent = document
        .querySelectorAll('.posts article')

    tabs.forEach((tab, i) => {
        tab.addEventListener('click', (e) => {
            e.preventDefault()
            let targetClass = ''

            document
                .querySelectorAll(
                    '.career-tabs .active'
                ).forEach((activeTab) => {
                    activeTab.classList.remove('active')
                })

            tab.classList.forEach((tabClass) => {
                if ( tabClass.startsWith('post-') )
                    targetClass = tabClass.toString()
            })

            tabContent.forEach((content) => {
                content.style.display = 'none'
            })

            document.querySelector('.posts .'+ targetClass).style
                .display = 'flex'

            tab.classList.add('active')
        })

        i === 0 &&
            tab.classList.add('active')
    })

    tabContent.forEach((content, i) => {
        if ( i !== 0 ) content.style.display = 'none'
    })
}

const smoothScrollTo = () => {
    let items = document.querySelectorAll('a[href^="#"]')

    items.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault()

            const href = item.getAttribute("href").toString()
            if ( href === '#' ) return

            const target = document.querySelector(href)
            const offsetTop = target.getBoundingClientRect().top + 
                document.documentElement.scrollTop - 60
            let focusedItem = document.querySelectorAll('.toc-focused')

            focusedItem &&
                focusedItem.forEach((active) => {
                    active.classList.remove('toc-focused')
                })

            item.classList.add('toc-focused')
            target.classList.add('toc-focused')

            window.scrollTo({
                top: offsetTop,
                behavior: "smooth"
            })

        })
    })
}

const elementorTocRenderList = () => {
    const elementorToc = document
        .querySelectorAll('.elementor-widget-table-of-contents')

    if ( elementorToc.length ) {
        elementorToc.forEach((toc) => {
            let tocBody = toc.querySelector('.elementor-toc__body')
            let h3Headings = document.querySelectorAll('h3')
            let itemList = ''
            
            h3Headings.forEach((heading, i) => {
                let anchor = 'anchor-'+ i
                let parentSection = heading.closest('.elementor-section')

                if ( parentSection ) {
                    parentSection.id = anchor

                    itemList += 
                        '<li class="elementor-toc__list-item">'+
                            '<div class="elementor-toc__list-item-text-wrapper">'+
                                '<a href="#' + anchor +'" '+
                                        'class="elementor-toc__list-item-text '+
                                            'elementor-toc__top-level">'+
                                    heading.textContent +
                        '</li>'
                }
            })

            tocBody.innerHTML = 
                '<ol class="elementor-toc__list-wrapper">'+ 
                    itemList +
                '</ol>'
        })
    }
}

const svgParralax = {
    replaceImgWithSVG(selector) {
        const imgToSVG = document.querySelector(selector)
        
        if ( !imgToSVG ) return  
        
        var imgURL = './img/pdr-grm-lts.svg'

        fetch(imgURL).then(function(response) {
            return response.text()
        
        }).then(function(data) {
            var parser = new DOMParser()
            var xmlDoc = parser.parseFromString(data, 'image/svg+xml')
            var svgElement = xmlDoc.getElementsByTagName('svg')[0]

            var toReplace = document.querySelector(selector + ' img')

            toReplace.parentNode &&
                toReplace.parentNode.replaceChild(svgElement, toReplace)
        })
     
        window.removeEventListener('scroll', svgParralax.scrollEvent)
    },
    scrollEvent() {
        svgParralax.replaceImgWithSVG('#top-image')
    },
    init() {
        window.addEventListener('scroll', svgParralax.scrollEvent)
        
        window.addEventListener('scroll', () => {
            document.body.style.setProperty(
                '--scroll', 
                1 + window.scrollY / (document.body.offsetHeight 
                    - window.innerHeight
            )
        )
        }, false)
    }
}

const elementorStickyFx = () => {
    const stickyItems = document.querySelectorAll('[data-settings*="sticky"]')
    const contactOffset = ( document.getElementById('contact-us') ) ? 
        document.getElementById('contact-us').getBoundingClientRect().top + 
            document.documentElement.scrollTop : 0
    const stikyCancelBottom = contactOffset - window.innerHeight

    stickyItems.forEach((item) => {
        let iClasses = item.classList
        let iOffsetTop = item.getAttribute('data-offset-top') 
        let settings = JSON.parse(item.getAttribute('data-settings'))
        let windowScroll = window.scrollY
        let activeStatus = iClasses.contains('sticky-active')
        let stickyOffset = settings.sticky_offset | 0
        let stickyOTP = stickyOffset

        if ( item.querySelector('.elementor-toc__body') )
            stickyOTP = stickyOTP + item.querySelector('.elementor-toc__body')
                .style.paddingTop

        if ( !iClasses.contains('sticky') ) {
            item.style.width = item.offsetWidth/(window.innerWidth/100) +'vw'
            item.setAttribute('data-offset-top', item.getBoundingClientRect()
                .top + document.documentElement.scrollTop)
            iClasses.add('sticky')
        } 
        
        if ( iClasses.contains('sticky') ) {
            if ( settings.sticky === "top"  ) {
                if ( windowScroll < stikyCancelBottom ) {
                    item.style.top = 0
    
                    if ( (windowScroll > iOffsetTop 
                        && windowScroll < contactOffset) 
                        && !activeStatus ) {
                            item.style.transform = 
                                'translateY('+ stickyOTP + 'px)'
                            iClasses.add('sticky-active')
                    } 
        
                    if ( iOffsetTop > windowScroll && activeStatus ) {
                        item.style.transform = ''
                        iClasses.remove('sticky-active')
                    }
    
                } else {
                    item.style.top = (stikyCancelBottom - windowScroll) +'px'
    
                }
    
            } 
        }

    })
}

const webFlipEvent = () => {
    let staticFlipElement = document.querySelectorAll('.static-flip')

    if ( staticFlipElement.length > 0 ) {
        const webpFlip = {
            ids: [
                'analytics-slider',
                'workflow-slider',
                'iforms-slider',
            ],
            switchSlide(id, visible) {
                let parent = document.getElementById(id)
                let childImg = parent
                    .querySelector('.elementor-widget-container > img')

                if ( !childImg ) return false
                
                let src = childImg.getAttribute('src')
                let srcset = childImg.getAttribute('srcset')
                let dataSrc = parent.getAttribute('data-src')
                let dataSrcset = parent.getAttribute('data-srcset')

                parent.setAttribute('data-src', src)
                parent.setAttribute('data-srcset', srcset)

                childImg.src = dataSrc
                childImg.srcset = dataSrcset

                this.setStatus(id, visible)
            },
            getIds() {
                return this.ids
            },
            isInSight(id) {
                let element = document.getElementById(id)

                if ( element ) {
                    let hT = element.getBoundingClientRect().top +
                        document.documentElement.scrollTop
                    let hH = element.offsetHeight
                    let wH = window.innerHeight
                    let wS = window.scrollY
    
                    return wS > (hT + hH - wH)
                }
            },
            setStatus(id, visible) {
                let element = document.getElementById(id)

                if ( visible && !element.classList.contains('playing') ) {
                    element.classList.add('playing')

                } else {
                    element.classList.remove('playing')

                }
            },
            isPlaying(id) {
                let target = document.getElementById(id)

                return (target) ? target.classList.contains('playing') : false

            }
        }

        document.onscroll = () => {
            let ids = webpFlip.getIds()

            for ( let id of ids ) {
                if ( webpFlip.isInSight(id) ) {
                    for ( let toOff of ids ) {
                        if ( toOff !== id && webpFlip.isPlaying(toOff) )
                        webpFlip.switchSlide(toOff, false)

                    }

                    !webpFlip.isPlaying(id) &&
                        webpFlip.switchSlide(id, true)

                    return false
                }
            }
        }
    }
}

const formIframeSize = () => {
    if ( window.hasOwnProperty('rm') || typeof window.rm === 'function')
        return

    window.rm = function (event) {  
        const pddomain = "info.grmdocumentmanagement.com"
        const selector = 'iframe[src*="' + pddomain + '"]'
        let regexp = new RegExp(pddomain)
        let isResize = event.data.hasOwnProperty('msg') &&
                        event.data.msg === "resize"

        if ( regexp.test(event.origin) && isResize ) {
            let matches = document.querySelectorAll( selector )

            for ( let i = 0; i < matches.length; i++ ) {
                if ( matches[i].contentWindow === event.source ){
                    if ( !isNaN( event.data.height ) ) {
                        matches[i].height = Number( event.data.height )

                        return 1
                    }
                }
            }
        }
    }

    window.addEventListener("message", window.rm, false)
}

const initTabs = () => {
    document.querySelectorAll('.elementor-tab-title')[0].classList
        .add('elementor-active')
    document.querySelectorAll('[data-tab="1"][role="tabpanel"]')[0].style
        .display = 'block'
}

const tabsOnClick = () => {
    let tabSelect = document.querySelectorAll('[role="tablist"] [role="tab"]')

    tabSelect.forEach(function (v, i) {
        let _this = tabSelect[i]

        _this.addEventListener('click', function (e) {
            let tab = _this.getAttribute('data-tab')
            let activeTab = document
                .querySelectorAll('[role="tablist"] .elementor-active')[0]
            let activeTabContent = document
                .querySelectorAll('[role="tabpanel"][style]')[0]

            console.log(tab)

            activeTab.classList.remove('elementor-active')
            activeTabContent.removeAttribute('style')

            _this.classList.add('elementor-active')
            document.querySelectorAll(
                '[data-tab="' + tab + '"][role="tabpanel"]'
            )[0].style.display = 'block'

        })
    })
}

const videoEmbedInit = () => {
    let videos = document.querySelectorAll('[data-widget_type="video.default"]')

    videos.forEach(function (v, i) {
        let _this = videos[i]

        if ( _this.querySelector('.elementor-custom-embed-image-overlay') ) 
            return false
        
        let videoData = ( _this.getAttribute('data-settings') ) ?
            JSON.parse(_this.getAttribute('data-settings')) :
            false

        let embedUrl = ( videoData && videoData.youtube_url ) ? 
            videoData.youtube_url.replace('watch?v=', 'embed/') :
            false

        if ( !_this.querySelector('iframe') && videoData ) {
            const iframeOptions = {
                width: 640,
                height: 360,
                frameborder: 0,
                allowfullscreen: 1,
                allow: 'accelerometer autoplay clipboard-write '+
                    'encrypted-media gyroscope picture-in-picture web-share'
            }
            const newIframe = document.createElement('iframe', iframeOptions)

            newIframe.setAttribute('width', iframeOptions.width)
            newIframe.setAttribute('height', iframeOptions.height)
            newIframe.setAttribute('frameborder', iframeOptions.frameborder)
            newIframe.setAttribute('allowfullscreen', iframeOptions.allowfullscreen)
            newIframe.setAttribute('allow', iframeOptions.allow)

            _this.querySelector('.elementor-video').appendChild(newIframe)

        }

        embedUrl &&
        _this.querySelector('iframe').setAttribute('src', embedUrl)
    })
}

const videoEmbedLegacyInit = () => {
    let videos = document.querySelectorAll('.youtube')

    videos.forEach(function (v, i) {
        let _this = videos[i]
        let iframe = _this.querySelector('iframe')
        let embedUrl = _this.getAttribute('data-embed').split('&')

        iframe.setAttribute(
            'src',
            'https://youtube.com/embed/' + embedUrl[0]
        )

        _this.querySelector('.play-button')
            .addEventListener('click', function (e) {
                _this.querySelector('.play-button').remove()
                _this.querySelector('img').remove()
                iframe.removeAttribute('style')
            })
    })
}
